.container2 {
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0rem;
}

.container2 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 90px;
  font-weight: bold;
  letter-spacing: -1.95px;
  line-height: 113px;
  text-align: center;
  margin-bottom: 28px;
}

.container2 .sec2_p_web {
  font-size: 25px;
  letter-spacing: -0.35px;
  line-height: 37px;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
  font-family: "Playfair Display", serif;
}
.sec2_p_mobile {
  display: none;
}
.container2 .pre-1 {
  font-size: 20px;
  letter-spacing: -0.35px;
  font-weight: 500;
  line-height: 37px;
  margin: 10px 0;
  text-align: center;
  font-family: "Playfair Display", serif;
}

.pre-1-mobile {
  display: none;
}

.pre_1_1 {
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 37px;
  margin: 10px 0;
  /* margin-bottom: 174px; */
  text-align: center;
  font-family: "Playfair Display", serif;
}
.pre_1_1_mo {
  display: none;
}
@media screen and (max-width: 430px) {
  .container2 {
    background-size: 60%;
    background-position: inherit;
    padding: 1.8rem 0rem;
  }

  .container2 h1 {
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: -1.95px;
    line-height: 95px;
    text-align: center;
    margin-bottom: 2px;
    margin: 0;
  }

  .sec2_p_mobile {
    display: block;
    font-size: 14px;
    letter-spacing: -0.18px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 15px;
    font-family: "Playfair Display", serif;
    /* font-family: "Poppins", sans-serif; */
  }
  .sec2_p_web {
    display: none;
  }

  .pre-1 {
    display: none;
  }
  .pre-1-mobile {
    text-align: center;
    display: block;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 18px;
    /* font-family: "Poppins", sans-serif; */
    font-family: "Playfair Display", serif;
    margin-bottom: 15px;
  }
  .pre_1_1 {
    display: none;
  }
  .pre_1_1_mo {
    text-align: center;
    display: block;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 18px;
    font-family: "Playfair Display", serif;
    /* font-family: "Poppins", sans-serif; */
    margin-bottom: 35px;
  }
}
