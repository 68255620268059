.container10 {
  width: 100vw;
  /* padding: 6rem 1rem; */
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(./img/nine-bg.png); */
  background-image: url("../../../../Assets/nine-bg.png");
  background-size: cover;
}
.div_of_body_10 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-div-of-container10 {
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.div_of-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.txt-head {
  width: 912px;
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.div-of-data-heading {
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 30px;
  margin: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: -0.355px;
}
.main_div_of_body_cntn10 {
  padding-top: 3rem;
  gap: 5rem;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.main_div_of_body_cntn11 {
  padding-top: 3rem;
  gap: 5rem;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.img_1_cntn10 {
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: contain;
  /* height: 90px; */
  flex-shrink: 0;
}

.right_side_div_2_AR {
  width: 65%;
  flex-shrink: 0;
  margin: 0;
  font-size: 17px;
  letter-spacing: -0.35px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: end;
}
.right_side_div_friend {
  width: 533px;
  /* height: 145px; */
  flex-shrink: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  line-height: 25px; /* 185% */
  letter-spacing: -0.355px;
}
.right_side_div_buisness {
  width: 545px;
  /* height: 145px; */
  flex-shrink: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 185% */
  letter-spacing: -0.355px;
}
.div_of_both {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  /* height: 145px; */
  flex-shrink: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 185% */
  letter-spacing: -0.355px;
}
@media screen and (max-width: 885px) {
  .container10 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .div_of-heading {
    width: 100% !important;
    gap: 1rem;
  }
  .txt-head {
    width: 100%;
    font-size: 32px;
  }
  .div-of-data-heading {
    font-size: 19px;
  }
  .main-div-of-container10 {
    width: 81%;
  }
  .right_side_div_2_AR {
    width: 405px;
  }
  .div_of_body_10 {
    width: 100%;
    gap: 2rem;
  }
  .main_div_of_body_cntn10 {
    padding-top: 0;
    gap: 2rem;
  }
  .right_side_div_buisness {
    width: 398px;
  }
  .main_div_of_body_cntn11 {
    padding-top: 0;
    gap: 2rem;
  }
  .div_of_both {
    width: 395px;
  }
  .right_side_div_friend {
    width: 390px;
  }
}
@media screen and (max-width: 430px) {
  .main-div-of-container10 {
    width: 91%;
    gap: 1rem;
  }
  .div-of-data-heading {
    text-align: center !important;
    width: 90% !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
  }
  .main_div_of_body_cntn10 {
    flex-direction: column;
    align-items: center;
  }
  .right_side_div_2_AR {
    text-align: center !important;
    width: 90% !important;
    font-size: 13px !important;
    line-height: 22px !important;
  }
  .right_side_div_buisness {
    text-align: center;
    width: 303px;
  }
  .main_div_of_body_cntn11 {
    flex-direction: column;
    align-items: center;
  }
  .div_of_both {
    text-align: center;
    width: 317px;
  }
  .right_side_div_friend {
    text-align: center;
    width: 319px;
  }
}
@media screen and (max-width: 394px) {
  .right_side_div_2_AR {
    /* width: 363px; */
    text-align: center !important;
    width: 90% !important;
    font-size: 13px !important;
    line-height: 22px !important;
  }
  .txt-head {
    font-size: 23px;
  }
  .img_1_cntn10 {
    width: 214px;
    height: 62px;
  }

  .div-of-data-heading {
    text-align: center !important;
    width: 90% !important;
    font-size: 13px !important;
    line-height: 22px !important;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
  }
  .right_side_div_buisness {
    font-size: 8px;
    /* width: 348px; */
    width: 247px;

    line-height: 17px;
  }
  .div_of_both {
    line-height: 17px;
    font-size: 8px;
    width: 365px;
  }
  .right_side_div_3 {
    width: 249px;
  }
  .right_side_div_4 {
    width: 200px;
  }
  .right_side_div_friend {
    font-size: 8px;
    /* width: 365px; */
    width: 275px;

    line-height: 17px;
  }
}
@media screen and (max-width: 360px) {
  .right_side_div_2_AR {
    width: 259px;
  }
  .div_of_both {
    width: 324px;
  }
  .div-of-data-heading {
    width: 303px;
  }
  .right_side_div_buisness {
    width: 247px;
  }
  .right_side_div_friend {
    width: 275px;
  }
  .right_side_div_3 {
    width: 249px;
  }
  .right_side_div_4 {
    width: 200px;
  }
}
