.container7 {
  padding: 4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff5ee;
  background-size: cover;
}

.saven-sec {
  gap: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.conten-1-AR h1 {
  color: #2b1d1d;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.71px;
  line-height: 60px;
  text-align: end;
}

.conten-1-AR h3 {
  display: flex;
  color: #2b1d1d;
  justify-content: flex-end;
  /* font-family: 'Poppins', sans-serif; */
  letter-spacing: -0.71px;
  line-height: 30px;
  /* padding-top: 30px;
    padding-bottom: 20px; */
}
.conten-1-AR .h3teg .ptag {
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  /* margin-right: 4px; */
  /* margin-left:4px; */
}

.conten-1-AR h5 {
  margin: 0;
  font-family: "Poppins", sans-serif;
  text-align: end;
}

.conten-1-AR p {
  font-family: "Open Sans", sans-serif;
  color: #2b1d1d;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 37px;
  padding-bottom: 24px;
  text-align: end;
}

.conten-img img {
  padding-top: 2rem;
  width: 90%;
  padding-left: 50px;
}

.download-social {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.icon-img-AR img {
  width: 120px;
  height: 50px;
}

.download-social h5 {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.icon-img-AR {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.heading_text_sec9_AR {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 430px) {
  .container7 {
    padding: 2rem 1rem;
  }

  .saven-sec {
    gap: 2rem;
    flex-direction: column;
  }

  .conten-1-AR h1 {
    font-size: 40px;
    line-height: 65px;
    text-align: center;
  }

  .conten-1-AR h3 {
    text-align: center;
    justify-content: center;
    font-size: 19px;
    letter-spacing: -0.35px;
    line-height: 30px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .conten-1-AR h5 {
    text-align: center;
  }

  .conten-1-AR p {
    text-align: center;
    font-size: 12px;
    letter-spacing: -0.35px;
    line-height: 30px;
    padding-bottom: 19px;
    padding-top: 10px;
  }

  .download-social {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .icon-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .conten-img img {
    padding-top: 1rem;
    width: 323px;
    padding-left: 15px;
  }
  .heading_text_sec9_AR {
    align-items: center;
    margin-bottom: 1rem;
  }
}
