.container5 {
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #030303;
  background-size: cover;
}

.fifth-sec {
  gap: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.left-side-font h1 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.71px;
  line-height: 60px;
}

.left-side-font p {
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 37px;
  /* font-weight:700; */
}
.pre {
  font-weight: bolder;
}

.right-side-img_nine img {
  width: 90%;
  padding-left: 50px;
  filter: drop-shadow(0px 0px 8px #c9c9c9d1);
}
@media screen and (max-width: 430px) {
  .container5 {
    padding: 2rem 1rem;
  }

  .fifth-sec {
    flex-direction: column;
    gap: 0rem;
  }

  .left-side-font h1 {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    padding-bottom: 25px;
  }

  .left-side-font p {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 15px;
    letter-spacing: -0.35px;
    line-height: 30px;
  }

  .right-side-img_nine img {
    width: 80%;
    padding-left: 50px;
    filter: drop-shadow(0px 0px 8px #c9c9c9d1);
  }
}
