h1,
h3,
p {
  margin: 0;
}

.container6 {
  background-image: url("../../../../Assets/bt_bg.png");
  width: 100vw;
  height: auto;
  background-position: center;
  background-size: cover;
  position: relative;
}

.six-sec {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
}

.mid-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mid-text h1 {
  padding-top: 3rem;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 100px;
  font-weight: 650;
  letter-spacing: 0.5px;
  /* line-height: 55px; */
  text-align: center;
}

.mid-text h3 {
  margin-top: 1rem;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1px;
  /* line-height: 40px; */
  text-align: center;
}

.mid_text_p {
  margin-top: 1rem;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: -0.15px;
  text-align: center;
}
.mid_text_p_mobile {
  display: none;
}

.bottom-img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
    bottom: 0;
    right: 0;
    left: 0; */
  margin: auto;
}

.bottom-img img {
  width: 80vw;
}

@media screen and (max-width: 430px) {
  .container6 {
    height: 41vh;
  }

  .six-sec {
    flex-direction: column;
    gap: 20rem;
    /* padding-top: 20px; */
  }

  .mid-text {
    padding-top: 2px;
    gap: 0.5rem;
    /* z-index: 1000; */
  }

  .mid-text h1 {
    padding-top: 50px;
    margin-top: 0px;
    font-size: 30px;
    font-weight: bold;
    line-height: 0px;
    text-align: center;
  }

  .mid-text h3 {
    font-size: 13px;
    padding-top: 25px;
    line-height: 0px;
    text-align: center;
  }
  .mid_text_p {
    margin-top: 1rem;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: -0.15px;
    text-align: center;
  }
  .mid_text_p {
    display: none;
  }

  .mid_text_p_mobile {
    display: block;
    /* margin-top: 1rem; */
    padding-top: 10px;
    width: 90%;
    text-align: center;
    flex-shrink: 0;
    font-size: 11px;
    letter-spacing: -0.35px;
    line-height: 20px;
    font-family: "Poppins", sans-serif;
    color: #fff;
  }

  .bottom-img {
    gap: 6rem;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  .bottom-img img {
    width: 90vw;
  }
}
