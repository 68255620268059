h1,
p {
  margin: 0;
}
.container4 {
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.four-sec {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.left-side-img img {
  width: 90%;
}

.right-side_AR {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.right-side_AR h1 {
  color: #2b1d1d;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.71px;
  line-height: 60px;
  text-align: end;
}

.right-side_AR p {
  color: #2b1d1d;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 37px;
  text-align: end;
}

@media screen and (max-width: 430px) {
  .container4 {
    padding: 3rem 1rem;
  }

  .four-sec {
    flex-direction: column;
  }

  .left-side-img img {
    width: 323px;
  }

  .right-side_AR {
    gap: 0rem;
  }
  .main-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .sec4-h1 {
    flex-direction: column;
    gap: 0px;
  }
  .sec4-h1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-side_AR h1 {
    font-size: 25px;
    line-height: 32px;
    text-align: center;
  }

  .right-side_AR p {
    text-align: center;
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 30px;
  }
}
