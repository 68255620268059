.container3 {
  max-width: 100% !important;
  height: auto;
  background-color: #fff5ee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;
}

.cont3-h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.conten {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.conten h1 {
  text-align: center;
  font-size: 40px;
}

.main-conten {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.left-side-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  height: 450px;
}

.left-side-text,
.right-side-text p {
  color: #000000;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: -0.35px;
  line-height: 30px;
}

.mid-side-img img {
  width: 500px;
}

.right-side-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  height: 450px;
}

@media screen and (max-width: 430px) {
  .container3 {
    padding: 0rem 0rem;
  }
  .cont3-h1 {
    flex-direction: column;
    gap: 0px;
    padding-bottom: 0px;
    padding-top: 29px;
  }
  .conten h1 {
    text-align: center;
    font-size: 25px;
    margin: 0;
  }

  .main-conten {
    gap: 0rem;
    flex-direction: column;
  }

  .left-side-text {
    width: 300px;
    height: 100%;
  }

  .left-side-text p {
    font-size: 13px;
    letter-spacing: -0.16px;
    line-height: 18px;
    margin: 0;
  }
  .mid-side-img {
    height: 100% !important;
  }
  .mid-side-img img {
    width: 350px;
  }

  .right-side-text {
    width: 300px;
    height: auto;
    padding: 0 0 29px 0;

    /* margin-bottom: 10px; */
  }

  .right-side-text p {
    font-size: 13px;
    letter-spacing: -0.16px;
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
