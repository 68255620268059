.container9 {
  padding: 6rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../../../Assets/nine-bg.png");
  background-size: cover;
  gap: 2rem;
}
.nine-sec-gr {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nine_heding {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -0.71px;
  line-height: 40px;
  text-align: center;
}
.nine_heding_mobile {
  display: none;
}
.nine_pre_text a,
.nine_pre_text_mobile a {
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
}

.nine_pre_text {
  color: #ffffff;
  font-size: 17px;
  letter-spacing: -0.35px;
  line-height: 28px;

  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.nine_pre_text_mobile {
  display: none;
}
.left-side-font_nine_gr {
  width: 45%;
}

@media screen and (max-width: 430px) {
  .container9 {
    padding: 2rem 1rem;
  }

  .nine-sec-gr {
    flex-direction: column;
    gap: 1rem;
  }

  .nine_heding {
    font-size: 25px;
    line-height: 35px;
    padding-bottom: 25px;
    display: none;
  }
  .nine_heding_mobile {
    display: block;
    line-height: 35px;
    padding-bottom: 0px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.71px;
    text-align: center;
  }
  .nine_pre_text a,
  .nine_pre_text_mobile a {
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
  }

  .nine_pre_text_mobile {
    color: #ffffff;
    display: block;
    font-weight: lighter;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.35px;
    line-height: 30px;
  }
  .nine_pre_text {
    display: none;
  }
  .left-side-font_nine_gr {
    width: 80%;
  }

  .right-side-img > img {
    width: 323px;
    padding-left: 0px;
    padding-top: 0px !important;
  }
}
