.main_div_trems {
  width: 100vw;
  height: 100vh;
  background-image: url("../../Assets/bt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.main_div_trems > p {
  position: absolute;
  z-index: 9999;
  font-size: 50px;
  font-weight: 800;
  top: 5%;
  left: 5%;
  color: #fff;
  cursor: pointer;
  padding: 10px 1rem;
}
.main_div_trems > p:hover {
  background-color: #fff;
  color: #e84343;
  border-radius: 30%;
  padding: 10px 1rem;
}

.inner_div_trems {
  width: 100%;
  height: 95%;
  /* background-color: blanchedalmond; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.heading_text {
  width: max-content;
  padding: 1rem;
  border-bottom: 1px solid #fff;
}
.heading_text > h1 {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.inner_text_main_div {
  width: 80%;
  height: 90%;
  /* background-color: beige; */
  color: white;
  overflow-y: scroll;
  padding: 1rem;
  text-align: start;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
}

.inner_text_main_div::-webkit-scrollbar {
  display: none !important;
}

@media screen and (max-width: 430px) {
  .main_div_trems > p {
    position: absolute;
    z-index: 9999;
    font-size: 20px;
    font-weight: 800;
    top: 5%;
    left: 2%;
    color: #fff;
    cursor: pointer;
    padding: 10px 1rem;
  }
  .heading_text > h1 {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .inner_text_main_div {
    padding: 0;
  }
}
