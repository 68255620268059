.cont-1 {
  max-width: 1200px;
  width: 75%;
  margin: auto;
  /* padding-left: 85px; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 2em;
  overflow: hidden;
}

.head-sec {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../../Assets/bg.png");
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-sec .text-area {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.head-sec .text-area h1 {
  /* border: 2px solid black ; */
  width: 370px;
  font-family: "Poppins", sans-serif;
  position: relative;
  margin: 0;
  color: #fff;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: -1.597px;
}
.head-sec .text-area h1 span {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: 400;
  color: #f6f5f5;
  position: absolute;

  bottom: 6%;
}

.head-sec .text-area p {
  color: white;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.head-sec .text-area .img2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.head-sec .text-area .img2 p {
  font-size: 18px;
}

.head-sec .header-img img {
  margin-top: 5.5rem;
  width: 100%;
}
.box-1 {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 430px) {
  .head-sec {
    height: max-content;
    padding: 6rem 0rem 2rem 0rem;
  }

  .head-sec .cont-1 {
    width: 90%;
    flex-direction: column;
    padding-left: 0px;
    gap: 4rem;
  }

  .head-sec .text-area h1 {
    width: 320px;
    font-size: 55px;
    letter-spacing: 1px;
    line-height: 78px;
    text-align: center;
  }
  .head-sec .text-area h1 span {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #f6f5f5;
    position: absolute;

    bottom: 6%;
  }

  .head-sec .text-area p {
    text-align: center;
  }

  .box-1 {
    display: flex;
    gap: 10px;
  }

  .head-sec .header-img img {
    margin-top: 0rem;
    height: auto;
    width: 100%;
  }
}
