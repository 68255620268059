.container8 {
  background-color: #ffffff;
  padding: 1rem 0rem;
  width: 100vw;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contect-sec {
  margin-top: 2rem;
  gap: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container8 h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 650;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  padding-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}

.name-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.contect-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 20px;
  font-family: "Open Sans", sans-serif;
}

.First-name,
.Last-name,
.Email,
.Mobile-number,
.Message0 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.First-name,
.Last-name,
.Email,
.Mobile-number,
.Message0 label {
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
}

.f-name,
.l-name,
.email,
.M-number {
  display: flex;
}

.name-box2 {
  border: 0.5px solid #6363637d;
  border-radius: 8px;
  width: 253px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Open Sans", sans-serif;
}

.name-box2 input {
  width: 200px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.name-box2 input::placeholder {
  color: #212529;
  font-family: "Open Sans", sans-serif;
}

.Message {
  padding-top: 30px;
  font-family: "Open Sans", sans-serif;
}

.message1 {
  border: 0.5px solid #6363637d;
  border-radius: 8px;
  width: 545px;
  height: 135px;
  gap: 2rem;
  font-family: "Open Sans", sans-serif;
}

.message1 textarea {
  width: 500px;
  height: 125px;
  background: transparent;
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
}

.message1 textarea::placeholder {
  color: #212529;
  font-family: "Open Sans", sans-serif;
}

.submit {
  border: none;

  margin-top: 20px;
}

.submit input {
  color: #faf9f6;
  border: none;
  font-size: 1rem;
  /* width: 125px; */
  letter-spacing: 2.5px;
  background-color: #e2353c;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
}

/* right side image */
.right-side-img2 {
  border: 1px solid grey;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.right-side-img2 h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

.right-side-img2 p {
  text-align: center;
}

.right-side-img2 img {
  border-radius: 20px;
  width: 500px;
  height: 300px;
}
.errors_msg_p {
  color: red;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff;
}
@media screen and (max-width: 430px) {
  .container8 {
    padding: 2rem 0rem;
    height: auto;
    width: 100vw;
    flex-direction: column;
  }

  .container8 h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 650;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
  }

  .contect-sec {
    gap: 3rem;
    flex-direction: column;
  }

  .First-name,
  .Last-name,
  .Email,
  .Mobile-number,
  .Message0 label {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .name-form {
    flex-direction: column;
  }

  .contect-form {
    flex-direction: column;
  }

  .message1 {
    width: 345px;
    height: 135px;
  }

  .message1 textarea {
    width: 330px;
    height: 120px;
    padding: 8px;
  }

  .submit {
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    display: flex;
  }

  .right-side-img2 {
    gap: 1rem;
  }

  .right-side-img2 img {
    border-radius: 20px;
    width: 300px;
    height: 210px;
  }
}
