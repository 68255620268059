footer {
  background-color: #e2353c;
  padding-top: 10px;
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bottom-icon {
  max-width: 1200px;
  width: 80%;
  margin: auto;
  height: 100px;
  border-bottom: 1px solid #faf9f670;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  height: 23px;
  width: 65%;
  color: #faf9f6;
}
.left-text > .plink {
  text-decoration: none;
  color: #faf9f6;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.icon-bar {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #faf9f6;
  padding: 10pex 10px;
}

.icon-bar a {
  padding: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #faf9f6;
}

.ft-legal {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #faf9f6;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_last_text_web {
  font-size: 15px;
  line-height: 25px;
  font-family: "Playfair Display", serif;
  font-weight: 600;
}
.footer_last_text_mobile {
  display: none;
}
@media screen and (max-width: 430px) {
  footer {
    padding-top: 10px;
  }

  .bottom-icon {
    flex-direction: column;
    justify-content: center;
    width: 90%;
    gap: 10px;
  }

  .left-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 100%;
  }

  .left-text p {
    font-size: 10px;
  }

  .ft-legal {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .footer_last_text_web {
    display: none;
  }
  .footer_last_text_mobile {
    display: block;
    font-size: 10px;
  }

  .submit input {
    font-size: 12px;
    width: 100px;
    letter-spacing: 2px;
    border-radius: 15px;
    padding: 15px;
  }
  .left-text > .plink {
    text-decoration: none;
    color: #faf9f6;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
  }
}
